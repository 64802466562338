<template>
  <v-chart class="chartz" ref="chart" :manual-update="true" autoresize :loading="loading" />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import { ref, defineComponent, defineAsyncComponent, onMounted, toRefs, watch } from 'vue'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  LineChart,
  GridComponent,
])
import apiClient from '@/services/axios'

export default defineComponent({
  name: 'EChartTopRegion',
  components: {
    VChart,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    timestamp: {
      type: Object,
      default: () => ({
        startDate: null,
        endDate: null,
      }),
    },
  },
  setup: props => {
    const series = ref([])
    const xAxis = ref([])
    const loading = ref(false)
    const chart = ref()

    const option = ref({
      title: {
        text: props.title,
        left: 'center',
        textStyle: {
          overflow: 'break',
          fontSize: '1rem',
        },
      },
      textStyle: {
        overflow: 'break',
        fontSize: '0.8rem',
        width: 120,
      },
      grid: {
        containLabel: true,
        left: 30,
        right: 20,
        bottom: 20,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        type: 'scroll',
        bottom: 0,
        padding: [0, 0, 0, 0],
      },
      yAxis: [
        {
          type: 'category',
          data: xAxis.value,
          axisLabel: {
            show: false,
          },
          inverse: true,
          name: 'Nama Area',
          nameLocation: 'middle',
        },
      ],
      xAxis: [
        {
          type: 'value',
          name: 'Total Volume Penjualan (Ton)',
          nameLocation: 'middle',
          nameGap: 30,
          axisLabel: {
            formatter: function(a) {
              const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
              return formatter.format(a)
            },
          },
        },
      ],
      series: [
        {
          type: 'bar',
          data: series.value,
          showBackground: true,
          label: {
            show: true,
            formatter: '{b}',
            position: 'insideLeft',
          },
        },
      ],
      media: [
        {
          query: {
            maxWidth: 300, // when container width is smaller than 300
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 230,
                fontSize: '.9rem',
              },
            },
          },
        },
        {
          query: {
            minWidth: 300, // when container width is smaller than 300
            maxWidth: 500, // when container width is smaller than 500
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 300,
              },
            },
          },
        },
        {
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'none',
              },
            },
          },
        },
      ],
    })
    const fetchData = (start_date, end_date) => {
      loading.value = true
      apiClient
        .get(`/api/dashboard/top-area`, {
          params: { start_date, end_date },
        })
        .then(response => {
          const data = response.data
          loading.value = true

          series.value.splice(0, series.value.length)
          series.value.push(...data.map(i => parseFloat(i.qty).toFixed(2)))

          xAxis.value.splice(0, xAxis.value.length)
          xAxis.value.push(...data.map(i => i.area_name))

          chart.value.setOption(option.value)
        })
        .catch(error => console.error(error))
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData(props.timestamp.startDate, props.timestamp.endDate)
    })

    watch(
      () => ({ ...props.timestamp }),
      (n, o) => {
        fetchData(n.startDate, n.endDate)
      },
    )

    return { option, loading, chart }
  },
})
</script>

<style>
.chartz {
  height: 300px;
}
</style>
