<template>
  <base-chart :option="option" v-bind="$attrs" />
</template>

<script>
import BaseChart from '@/components/charts/BaseChart.vue'

import { defineComponent, computed, toRefs, reactive, ref } from 'vue'

import moment from 'moment'

export default defineComponent({
  name: 'EChart',
  components: {
    BaseChart,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    response: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const data = toRefs(props).response

    const brands = computed(() => [
      ...new Set(data.value.filter(i => i.brand_name).map(i => i.brand_name)),
    ])

    const res = computed(() => {
      const res = []
      const bulans = [...new Set(data.value.map(i => i.bulan))]

      bulans.forEach(b => {
        const obj = { bulan: b }
        brands.value.forEach(br => {
          const d = data.value.find(d => d.bulan === b && d.brand_name === br) || {
            qty: '0',
          }
          obj[br] = Number(d.qty).toFixed(2)
        })
        res.push(obj)
      })

      return res.sort((a, b) => (a.bulan.toLowerCase() > b.bulan.toLowerCase() ? 1 : -1))
    })

    const option = reactive({
      title: {
        text: props.title,
        left: 'center',
        textStyle: {
          overflow: 'break',
          fontSize: '1rem',
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      textStyle: {
        overflow: 'break',
        fontSize: '0.8rem',
        width: 120,
      },
      grid: {
        containLabel: true,
        left: 30,
        right: 10,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
      },
      dataset: [{ source: res }],
      legend: {
        type: 'scroll',
        bottom: 0,
        padding: [0, 0, 0, 0],
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            formatter: function(a) {
              return new moment(new Date(a)).format('MMM YY')
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Total Volume Penjualan',
          nameLocation: 'middle',
          nameGap: 43,
          axisLabel: {
            formatter: function(a) {
              const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
              return formatter.format(a)
            },
          },
        },
        {
          type: 'value',
          axisLabel: {
            formatter: function(a) {
              return a + ' %'
            },
          },
        },
      ],
      brands,
      series: computed(() =>
        brands.value.map(b => ({
          type: 'line',
          smooth: true,
        })),
      ),
      media: [
        {
          query: {
            maxWidth: 300, // when container width is smaller than 300
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 230,
                fontSize: '.9rem',
              },
            },
          },
        },
        {
          query: {
            minWidth: 300, // when container width is smaller than 300
            maxWidth: 500, // when container width is smaller than 500
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 300,
              },
            },
          },
        },
        {
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'none',
              },
            },
          },
        },
      ],
    })

    return { option, brands }
  },
})
</script>
