<template>
  <v-chart class="chartz" ref="chart" :manual-update="true" autoresize :loading="loading" />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import { ref, defineComponent, defineAsyncComponent, onMounted, toRefs, watch } from 'vue'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  LineChart,
  GridComponent,
])
import apiClient from '@/services/axios'

export default defineComponent({
  name: 'EChartDataKokoh',
  components: {
    VChart,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    timestamp: {
      type: Object,
      default: () => ({
        startDate: null,
        endDate: null,
      }),
    },
  },
  setup: props => {
    const totalTokoBk = ref([])
    const totalTokoTransaksiBk = ref([])
    const totalTokoTanpaIdBk = ref([])
    const loading = ref(false)
    const chart = ref()

    const option = ref({
      title: {
        text: props.title,
        left: 'center',
        textStyle: {
          overflow: 'break',
          fontSize: '1rem',
        },
      },
      textStyle: {
        overflow: 'break',
        fontSize: '0.8rem',
        width: 120,
      },
      grid: {
        containLabel: true,
        left: 0,
        right: 0,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        type: 'scroll',
        bottom: 0,
        padding: [0, 0, 0, 0],
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            interval: 0,
          },
          data: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: function(a) {
              const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
              return formatter.format(a)
            },
          },
        },
      ],
      series: [
        {
          name: 'Total Toko Register',
          type: 'bar',
          stack: 'total',
          data: totalTokoBk.value,
        },
        {
          name: 'Total Transaksi',
          type: 'bar',
          stack: 'total',
          data: totalTokoTransaksiBk.value,
        },
        {
          name: 'Toko Tanpa ID',
          type: 'bar',
          stack: 'total',
          data: totalTokoTanpaIdBk.value,
        },
      ],
      media: [
        {
          query: {
            maxWidth: 300, // when container width is smaller than 300
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 230,
                fontSize: '.9rem',
              },
            },
          },
        },
        {
          query: {
            minWidth: 300, // when container width is smaller than 300
            maxWidth: 500, // when container width is smaller than 500
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 300,
              },
            },
          },
        },
        {
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'none',
              },
            },
          },
        },
      ],
    })

    const fetchData = (start_date, end_date) => {
      loading.value = true
      apiClient
        .get(`/api/dashboard/bisnis-kokoh`, {
          params: { start_date, end_date },
        })
        .then(response => {
          const data = response.data
          loading.value = true

          // temp1.concat(data1.filter(d => !temp1.find(t => t.bulan === d.bulan))).sort((a,b) => Number(a.bulan) - Number(b.bulan))
          totalTokoBk.value.splice(0, totalTokoBk.value.length)
          totalTokoBk.value.push(...data.total_toko.map(i => i.total))

          totalTokoTransaksiBk.value.splice(0, totalTokoTransaksiBk.value.length)
          totalTokoTransaksiBk.value.push(...data.total_transaksi.map(i => i.total))

          totalTokoTanpaIdBk.value.splice(0, totalTokoTanpaIdBk.value.length)
          totalTokoTanpaIdBk.value.push(...data.total_toko_tanpa_id_bk.map(i => i.total))

          chart.value.setOption(option.value)
        })
        .catch(error => console.error(error))
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData(props.timestamp.startDate, props.timestamp.endDate)
    })

    watch(
      () => ({ ...props.timestamp }),
      (n, o) => {
        fetchData(n.startDate, n.endDate)
      },
    )

    return { option, loading, chart }
  },
})
</script>

<style>
.chartz {
  height: 300px;
}
</style>
