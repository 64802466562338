<template>
  <v-chart
    class="chartz"
    :option="option"
    :update-options="{ notMerge: true }"
    autoresize
    v-bind="$attrs"
  />
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DatasetComponent,
} from 'echarts/components'
import { defineComponent, toRefs, computed, reactive } from 'vue'

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  LineChart,
  GridComponent,
  ToolboxComponent,
  DatasetComponent,
])

export default defineComponent({
  name: 'EChart',
  components: {
    VChart,
  },
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    return {}
  },
})
</script>

<style>
.chartz {
  height: 300px;
}
</style>
