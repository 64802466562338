<template>
  <base-chart :option="option" v-bind="$attrs" />
</template>

<script>
import BaseChart from '@/components/charts/BaseChart.vue'

import { ref, defineComponent, toRefs, computed, reactive } from 'vue'

export default defineComponent({
  name: 'EChart',
  components: {
    BaseChart,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    response: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const res = computed(() => props.response)

    const option = computed(() => ({
      title: {
        text: props.title,
        left: 'center',
        textStyle: {
          overflow: 'break',
          fontSize: '1rem',
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      textStyle: {
        overflow: 'break',
        fontSize: '0.8rem',
        width: 120,
      },
      grid: {
        containLabel: true,
        left: 30,
        right: 10,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        formatter: function(params) {
          const data = params[0].data
          const formatter = Intl.NumberFormat('id-ID')

          return `
          Tanggal : ${data.tanggal} <br />
          Total :  ${formatter.format(data.sell_out)} ZAK <br />
          Sell Out Id: ${formatter.format(data.sell_out_id)} ZAK <br />
          Sell Out Non-Id  : ${formatter.format(data.total)} ZAK <br />
          `
        },
      },
      dataset: {
        dimensions: ['tanggal', 'sell_out', 'sell_out_id', 'total'],
        source: res.value,
      },
      legend: {
        type: 'scroll',
        bottom: 0,
        padding: [0, 0, 0, 0],
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            formatter: function(a) {
              return new Date(a)
                .getDate()
                .toString()
                .padStart(2, '0')
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Total Volume Penjualan ( ZAK )',
          nameLocation: 'middle',
          nameGap: 43,
          axisLabel: {
            formatter: function(a) {
              const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
              return formatter.format(a)
            },
          },
        },
        {
          type: 'value',
          axisLabel: {
            formatter: function(a) {
              return a + ''
            },
          },
        },
      ],
      series: [
        {
          name: "Total Sell Out          (ID & Non-ID)",
          type: 'line',
          smooth: true,
          color: 'orange',
        },
        {
          name: 'Sell Out Id (ZAK)',
          type: 'line',
          smooth: true,
        },
        {
          name: 'Sell Out Non-Id (ZAK)',
          type: 'line',
          smooth: true,
        },
      ],
      media: [
        {
          query: {
            maxWidth: 300, // when container width is smaller than 300
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 230,
                fontSize: '.9rem',
              },
            },
          },
        },
        {
          query: {
            minWidth: 300, // when container width is smaller than 300
            maxWidth: 500, // when container width is smaller than 500
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 300,
              },
            },
          },
        },
        {
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'none',
              },
            },
          },
        },
      ],
    }))

    return { option }
  },
})
</script>
